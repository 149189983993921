import React from 'react'
import Layout from '../components/Layout'
import * as Hero from '../components/Hero'

import styles from './404.module.css'

const NotFoundPage = () => (
  <Layout>
    <div className={styles.component}>
      <Hero.Box className={styles.hero}>
        <Hero.Title className={styles.heroTitle}>Oops!</Hero.Title>
        <Hero.Lead className={styles.heroLead}>
          We cannot seem to find the page you were looking for.
        </Hero.Lead>
      </Hero.Box>
    </div>
  </Layout>
)

export default NotFoundPage
